import React, { useState, useEffect, useCallback } from 'react';
import Header from '../../components/Header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SuccessNotification from '../../components/Notifications/SuccessNotification';
import ErrorNotification from '../../components/Notifications/ErrorNotification';
import LoginNotification from "../../components/Notifications/LoginNotification";
import { faDatabase, faEdit, faTrashAlt, faEye, faCircleCheck, faCircleXmark, faSearch } from '@fortawesome/free-solid-svg-icons';
import Table from '../../components/Table';
import Section from '../../components/Section';
import SlideModal from '../../components/SlideModal';
import apiClient from "../../axios";
import { Tooltip } from "react-tooltip";
import { useLocation, useNavigate } from 'react-router-dom';

const ConnectionKpiList = ({ handleLogout }) => {
    const location = useLocation();
    const { numCont, companyId } = location.state || {};
    const [data, setData] = useState([]);
    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const itemsPerPage = 10;
    const [isSuccessVisible, setIsSuccessVisible] = useState(false);
    const [isErrorVisible, setIsErrorVisible] = useState(false);
    const [isInfoVisible, setIsInfoVisible] = useState(false);
    const navigate = useNavigate();
    const [selectedItem, setSelectedItem] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const hasPermission = (permission) => {
        const permissions = JSON.parse(localStorage.getItem('permissions')) || [];
        return permissions.includes(permission);
    };

    const toggleModal = async (item) => {
        if (!isModalOpen) {
          try {
            //const response = await apiClient.get(`/${item.contractId}/connectionToken/${item.id}`);
            const response = await apiClient.get(`/connectionToken/${item.id}`);
            const connection = {
              general: item,
              alternative: response.data,
            };
            setSelectedItem(connection);
            setIsModalOpen(true);
          } catch (error) {
            setIsErrorVisible(true);
            if (error.response && error.response.status === 401) {
              setIsInfoVisible(true);
            }
          } finally {
            setIsSuccessVisible(true);
          }
        } else {
          setIsModalOpen(false);
        }
      };

    const fetchAllData = useCallback(async (page = 1) => {
        try {
            setLoading(true);
            //const response = await apiClient.get(`/${numCont}/connectionToken/company/${companyId}?page=${page}`);
            const response = await apiClient.get(`/connectionToken/company/${companyId}?page=${page}`);
            if (Array.isArray(response.data.results)) {
                setData(response.data.results);
                setTotalItems(1);
            } else {
                setData([response.data.results]);
                setTotalItems(1);
            }
        } catch (error) {
            setIsErrorVisible(true);
            if (error.response && error.response.status === 401) {
                setIsInfoVisible(true);
            }
        } finally {
            setIsSuccessVisible(true);
            setLoading(false);
        }
    }, [companyId]);

    useEffect(() => {
        if(numCont) {
            fetchAllData();
        }
    }, [fetchAllData, numCont, companyId]);

    const handleRefresh = () => {
        fetchAllData(currentPage);
    };

    
    const handleSearch = useCallback(async (page = 1) => {
        try {
            const params = new URLSearchParams();
            if (search) params.append('search', search);

            //const response = await apiClient.get(`/${numCont}/connectionToken/company/${companyId}?${params.toString()}`);
            const response = await apiClient.get(`/connectionToken/company/${companyId}?${params.toString()}`);
            if (Array.isArray(response.data)) {
                setData(response.data);
            } else {
                setData([response.data]);
            }
            setTotalItems(response.data.total);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                setIsInfoVisible(true);
            }
        }
    }, [search, companyId]);
    
    
    const handleFresh = async (code) => {
        handleSearch(1)
    };

    const handleDelete = async (id) => {
        try {
            const url = `/connectionToken/${id}`;
            await apiClient.delete(url);
            handleRefresh();
        } catch (error) {
            setIsErrorVisible(true);
            if (error.response && error.response.status === 401) {
                setIsInfoVisible(true);
            }
        }
    };

    const columns = [
        { title: "Id", key: "id" },
        { title: "Contrato", key: "contractId" },
        { title: "Compañía", key: "companieId" },
        { title: "Módulo ID", key: "moduleId" },
        { title: "Módulo", key: "module" },
        { title: "Estado", key: "flagActive" },
        { title: "Acciones", key: "acciones" },
    ];

    const renderRow = (item, index) => (
        <>
            <td>{item.id}</td>
            <td>{item.contractId}</td>
            <td>{item.companieId}</td>
            <td>{item.moduleId}</td>
            <td>{item.module}</td>
            <td>
                {item.flagActive ? (
                    <button
                        className="status_button"

                    >
                        <FontAwesomeIcon
                            icon={faCircleCheck}
                            style={{ color: "green", fontSize: "24px" }}
                        />
                    </button>
                ) : (
                    <button
                        className="status_button"

                    >
                        <FontAwesomeIcon
                            className="status_icon"
                            icon={faCircleXmark}
                            style={{ color: "red", fontSize: "24px" }}
                        />
                    </button>
                )}
            </td>
            <td>
                <div className="button-container">
                    <Tooltip id="edit-tooltip" className="custom-tooltip" />
                    {hasPermission('CREDENTIAL_KPI_UPDATE') && (
                        <button
                            data-tooltip-id="edit-tooltip"
                            className="icon-button edit-button"
                            data-tooltip-content="Editar"
                            onClick={(e) => {
                                e.stopPropagation();
                                navigate(`/ConnecctionKpiForm/${item.id}`, { state: { numCont: numCont, companyId: companyId } });
                            }}
                        >
                            <FontAwesomeIcon icon={faEdit} />
                        </button>
                    )}
                    <Tooltip id="delete-tooltip" className="custom-tooltip" />
                    {hasPermission('CREDENTIAL_KPI_DELETE') && (
                        <button
                            data-tooltip-id="delete-tooltip"
                            data-tooltip-content="Eliminar"
                            className="icon-button delete-button"
                            onClick={() => handleDelete(item.id)}
                        >
                            <FontAwesomeIcon icon={faTrashAlt} />
                        </button>
                    )}
                    <Tooltip id="contract-tooltip" className="custom-tooltip" />
                    {hasPermission('CREDENTIAL_KPI_VIEW') && (
                    <button
                        className="icon-button company-button"
                        onClick={() => toggleModal(item)}
                        data-tooltip-id="contract-tooltip"
                        data-tooltip-content="Detalle"
                    >
                        <FontAwesomeIcon icon={faEye} />
                    </button>
                    )}
                </div>
            </td>
        </>
    );

    return (
        <div className="home-container">
            <Header onLogout={handleLogout} title='Conexiones KPI' />
            <div className="home-content">
                <Section>
                    <div className="filter-form">
                        <div className="basic-info-form-group">
                            <input
                                type="text"
                                id="customer"
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                                placeholder="Conexión KPI"
                            />
                        </div>
                        <button className="search-button-customer" onClick={handleFresh}>
                            <FontAwesomeIcon icon={faSearch} className="search-icon" />
                            Buscar
                        </button>
                    </div>

                    <div className="button-add" style={{ justifyContent: "flex-end", width: "100%" }}>
                        {hasPermission('CREDENTIAL_KPI_CREATE') && (
                            <button
                                className="basic-custom-button"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    navigate('/ConnecctionKpiForm', { state: { numCont: numCont, companyId: companyId } });
                                }}
                            >
                                <FontAwesomeIcon className="basic-shortcut-icon" icon={faDatabase} />
                                Crear Nueva Conexión Kpi
                            </button>
                        )}
                    </div>
                </Section>
                <Table
                    title="Lista de Conexiones KPI"
                    rows={data}
                    columns={columns}
                    icon={faDatabase}
                    renderRow={renderRow}
                    currentPage={currentPage}
                    totalItems={totalItems}
                    itemsPerPage={itemsPerPage}
                    onPageChange={setCurrentPage}
                    onRefresh={handleRefresh}
                    loading={loading}
                    flagPagination={true}
                />
                <SuccessNotification
                    message={"Se ha cargado correctamente"}
                    isVisible={isSuccessVisible}
                    onClose={() => setIsSuccessVisible(false)}
                />
                <ErrorNotification
                    message="Ups! Ocurrio un Problema"
                    isVisible={isErrorVisible}
                    onClose={() => setIsErrorVisible(false)}
                />
                <LoginNotification
                    message="Vuele a iniciar sesión"
                    isVisible={isInfoVisible}
                    onClose={() => setIsInfoVisible(false)}
                    onLogout={handleLogout}
                />
                <SlideModal isOpen={isModalOpen} onClose={toggleModal} title="Detalles de la Conexión">
                    <div className="section-container-form">
                        <h4 className="section-title-form">CÓDIGO</h4>
                        <hr className="section-divider-form" />
                        <div className="form-group-form">
                        <label>{selectedItem?.general.id}</label>
                        </div>

                        <h4 className="section-title-form">CONTRATO</h4>
                        <hr className="section-divider-form" />
                        <div className="form-group-form">
                        <label>{selectedItem?.general.contractId}</label>
                        </div>

                        <h4 className="section-title-form">COMPANÍA</h4>
                        <hr className="section-divider-form" />
                        <div className="form-group-form">
                        <label>{selectedItem?.general.companieId}</label>
                        </div>

                        <h4 className="section-title-form">MÓDULO ID</h4>
                        <hr className="section-divider-form" />
                        <div className="form-group-form">
                        <label>{selectedItem?.general.moduleId}</label>
                        </div>

                        <h4 className="section-title-form">MÓDULO</h4>
                        <hr className="section-divider-form" />
                        <div className="form-group-form">
                        <label>{selectedItem?.general.module}</label>
                        </div>

                        <h4 className="section-title-form">ESTADO</h4>
                        <hr className="section-divider-form" />
                        <div className="form-group-form">
                        <label>
                            {selectedItem?.general.flagActive === 1 ? "Activo" : "Inactivo"}
                        </label>
                        </div>
                        
                        <h4 className="section-title-form">TIPO DE CONEXIÓN</h4>
                        <hr className="section-divider-form" />
                        <div className="form-group-form">
                        <label>{selectedItem?.alternative.engine}</label>
                        </div>

                        <h4 className="section-title-form">NOMBRE DE LA BASE DE DATOS</h4>
                        <hr className="section-divider-form" />
                        <div className="form-group-form">
                        <label>{selectedItem?.alternative.dbName}</label>
                        </div>

                        <h4 className="section-title-form">HOST</h4>
                        <hr className="section-divider-form" />
                        <div className="form-group-form">
                        <label>{selectedItem?.alternative.dbHost}</label>
                        </div>

                        <h4 className="section-title-form">PUERTO</h4>
                        <hr className="section-divider-form" />
                        <div className="form-group-form">
                        <label>{selectedItem?.alternative.dbPort}</label>
                        </div>

                        <h4 className="section-title-form">USUARIO</h4>
                        <hr className="section-divider-form" />
                        <div className="form-group-form">
                        <label>{selectedItem?.alternative.dbUser}</label>
                        </div>

                        <h4 className="section-title-form">CONTRASEÑA</h4>
                        <hr className="section-divider-form" />
                        <div className="form-group-form">
                        <label>{selectedItem?.alternative.dbPass}</label>
                        </div>
                    </div>
                    </SlideModal>
            </div>
        </div>
    );
};

export default ConnectionKpiList;
