import React, { useState, useEffect, useCallback } from 'react';
import './Contract.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { Header, Table } from '../../components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faFileContract, faFileCirclePlus, faSearchPlus, faEdit, faCube, faCircleCheck, faCircleXmark, faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import Section from '../../components/Section';
import apiClient from "../../axios";
import { Tooltip } from "react-tooltip";
import ErrorNotification from '../../components/Notifications/ErrorNotification';
import SuccessNotification from '../../components/Notifications/SuccessNotification';
import LoginNotification from "../../components/Notifications/LoginNotification";
import DebtModal from "../../components/DebtModal";
import SlideModal from '../../components/SlideModal';
import ContractStatus from "../../components/Notifications/ContractStatus";
import ContractIdentifiersModal from '../../components/ContractIdentifiersModal';
import AddUserContract from '../../components/AddUserContract';

const Contract = ({ handleLogout }) => {

  const location = useLocation();
  const [data, setData] = useState([]);
  const [search, setSearch] = useState(''); // Estado definitivo para la búsqueda
  const [customer] = useState(location.state?.customer.code || ''); // Estado definitivo para la búsqueda
  const [cif] = useState(location.state?.customer.cif || ''); // Estado definitivo para la búsqueda
  const [name] = useState(location.state?.customer.socialReason || location.state?.customer.comercialName || ''); // Estado definitivo para la búsqueda
  const [totalItems, setTotalItems] = useState(0);
  const [tempSearch, setTempSearch] = useState(''); // Temporal para el input de contrato
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const [isSuccessVisible, setIsSuccessVisible] = useState(false);
  const [isErrorVisible, setIsErrorVisible] = useState(false);
  const [isInfoVisible, setIsInfoVisible] = useState(false);
  const itemsPerPage = 10;
  const [loading, setLoading] = useState(true);
  const role = localStorage.getItem('role');
  const distributorToken = localStorage.getItem('distributor');
  const [modalVisible, setModalVisible] = useState(false);
  const [debtModal, setDebtModal] = useState(false);
  const [message, setMessage] = useState("");
  const [isModalView, setIsModalView] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [viewItem, setViewItem] = useState(null);
  const [updateModal, setUpdateModal] = useState(false);
  const [addUserModal, setAddUserModal] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(null);

  const hasPermission = (permission) => {
    const permissions = JSON.parse(localStorage.getItem('permissions')) || [];
    return permissions.includes(permission);
  };

  // Función de búsqueda
  const handleSearch = useCallback(async (page = 1) => {

    try {
      let endPoint = 'clients';
      setLoading(true)
      if (search) {
        endPoint += `/${customer}/contracts?page=${page}&search=${search}`;
      } else {
        endPoint += `/${customer}/contracts?&page=${page}`;
      }

      const response = await apiClient.get(endPoint);
      if (Array.isArray(response.data.results)) {
        setData(response.data.results);
      } else {
        setData([response.data.results]);
      }
      setTotalItems(response.data.total);
      setLoading(false)
    } catch (error) {
      setLoading(false)
      if (error.response.data.error === "Unauthorized") {
        setIsInfoVisible(true);
      }
    }
  }, [search, customer]);

  // Ejecuta handleSearch cuando el componente se monta
  useEffect(() => {
    handleSearch(currentPage);
  }, [handleSearch, currentPage]);

  // Asigna los valores definitivos y ejecuta la búsqueda
  const assignSearchValues = () => {
    if (tempSearch) {
      setSearch(tempSearch);
    } else {
      setSearch(tempSearch);
    }
    handleSearch(1);
  };

  const onRefreshThis = () => {
    setTempSearch('');
    setSearch('');
    handleSearch(1);
  };



  const toggleModal = async (item) => {
    if (!isModalView) {
      try {
        const response = await apiClient.get(`/contracts/${item.numCont}`);
        const contract = {
          general: item,
          alternative: response.data,
        };
        setViewItem(contract);
        setIsModalView(true);
      } catch (error) {
        setIsErrorVisible(true);
        if (error.response && error.response.status === 401) {
          setIsInfoVisible(true);
        }
      } finally {
        setIsSuccessVisible(true);
      }
    } else {
      setIsModalView(false);
    }
  };
  const handleVerifyDebt = async (data, customer) => {
    try {
      const response = await apiClient.get(`/distributors/${distributorToken}`);
      if (response.data.purchaseBlock) {
        setMessage(response.data.blockReason)
        setDebtModal(true);
      }
      else {
        navigate('/PurchaseForm', { state: { contract: data, cif: customer } });
      }
    } catch (error) {
      setLoading(false)
      if (error.response.data.error === "Unauthorized") {
        setIsInfoVisible(true);
      }
    }


  };

  const handleStatusClick = (item) => {
    if (!item.checkobservacion && hasPermission('CONTRACT_BLOCK')) {
      setSelectedItem(item);
      setModalVisible(true);
    } else if (item.checkobservacion && hasPermission('CONTRACT_UNBLOCK')) {
      setSelectedItem(item);
      setModalVisible(true);
    } else {
      setIsErrorVisible(true);
      setTimeout(() => {
        setIsErrorVisible(false);
      }, 4000);
    }
  };
  const handleCloseModal = () => {
    setModalVisible(false);
    setSelectedItem(null);
    onRefreshThis()
  };

  const handleUpdateIdentifiers = (item) => {
    setSelectedItem(item);
    setUpdateModal(true);
  }
  const handleCloseUpdateModal = () => {
    setUpdateModal(false);
    setSelectedItem(null);
    onRefreshThis()
  };

  const handleAddUser = (item) => {
    setSelectedItem(item);
    setAddUserModal(true);
  }
  const handleCloseAddUser = () => {
    setAddUserModal(false);
    //setUpdateModal(false);
    setSelectedItem(null);
    onRefreshThis()
  };

  const handleCloseDebModal = () => {
    setDebtModal(false);
    onRefreshThis()
  };

  const columns = [
    { title: "Número de contrato", key: "numCont" },
    { title: "Fecha Vencimiento", key: "vencimiento" },
    { title: "Úlitma Fecha Ingreso", key: "lastLogin" },
    { title: "Tipo Contrato", key: "tipo" },
    { title: "Descripción", key: "descripcion" },
    { title: "Estado", key: "acciones" },
    { title: "Acciones", key: "acciones" },
  ];

  const renderRow = (item, index) => (
    <>
      <td >{item?.numCont}</td>
      <td>{new Date(item.expirationDate).toLocaleString()}</td>
      <td>{new Date(item.lastLoginDate).toLocaleString()}</td>
      <td >{item?.contractType}</td>
      <td >{item?.descripcion}</td>
      <td>
        {!item.checkobservacion ? (
          <button
            className="status_button"
            onClick={() => handleStatusClick(item)}
          >
            <FontAwesomeIcon
              icon={faCircleCheck}
              style={{ color: "green", fontSize: "24px" }}
            />
          </button>
        ) : (
          <button
            className="status_button"
            onClick={() => handleStatusClick(item)}
          >
            <FontAwesomeIcon
              className="status_icon"
              icon={faCircleXmark}
              style={{ color: "red", fontSize: "24px" }}
            />
          </button>
        )}
      </td>
      <td>
        <div className="button-container">
          <Tooltip id="edit-tooltip" className="custom-tooltip" />
          {hasPermission('CONTRACT_VIEW') && (
            <button
              className="icon-button company-button"
              onClick={() => toggleModal(item)}
              data-tooltip-id="contract-tooltip"
              data-tooltip-content="Detalle"
            >
              <FontAwesomeIcon icon={faEye} />
            </button>
          )}
          <Tooltip id="edit-tooltip" className="custom-tooltip" />
          {hasPermission('CONTRACT_UPDATE') && (role !== 'CHANEL_ADMIN' || item.saas === 0) && (
            <button
              data-tooltip-id="edit-tooltip"
              className="icon-button edit-button"
              data-tooltip-content="Editar"
              onClick={(e) => {
                e.stopPropagation();
                //handleRowClick(item)
                navigate(`/ContractNew/${item.numCont}`);
              }}
            >
              <FontAwesomeIcon icon={faEdit} />
            </button>
          )}
          <Tooltip id="contract-tooltip" className="custom-tooltip" />
          {hasPermission('MODULE_LIST') && (role !== 'CHANEL_ADMIN' || item.saas === 0) && (
            <button
              className="icon-button company-button"
              onClick={(e) => {
                e.stopPropagation();
                navigate('/ModulesForm', { state: { modules: item } });
              }}
              data-tooltip-id="contract-tooltip"
              data-tooltip-content="Módulos"
            >
              <FontAwesomeIcon icon={faCube} />
            </button>
          )}

          <div className="dropdown-container">
            <button
              className="icon-button dropdown-button"
              onClick={(e) => {
                e.stopPropagation();
                setOpenDropdown(openDropdown === item.numCont ? null : item.numCont);
              }}>
              <FontAwesomeIcon icon={faEllipsisH} />
            </button>
            {openDropdown === item.numCont && (
              <div className="dropdown-menu">
                {hasPermission('CONTRACT_UPDATE_IDENTIFIER') && (
                  <div
                    data-tooltip-id="contract-tooltip"
                    className="dropdown-item"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleUpdateIdentifiers(item);
                      setOpenDropdown(null);
                    }}
                  >
                    Editar Identificadores
                  </div>
                )}
                {hasPermission('ORDER_CREATE') && (
                  <div
                    className="dropdown-item"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleAddUser(item);
                      setOpenDropdown(null);
                    }}
                  >
                    Agregar usuarios
                  </div>
                )}
                {hasPermission('COMPANY_LIST') && (
                  <div
                    data-tooltip-id="edit-tooltip"
                    className="dropdown-item"
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate('/CompaniesList', { state: { numCont: item.numCont } });
                      setOpenDropdown(null);
                    }}
                  >
                    Companias
                  </div>
                )}
              </div>
            )}
          </div>
          
          {/*
          <Tooltip id="edit-tooltip" className="custom-tooltip" />
          {hasPermission('COMPANY_LIST') && (role !== 'CHANEL_ADMIN' || item.saas === 0) && (
            <button
              data-tooltip-id="edit-tooltip"
              className="icon-button edit-button"
              data-tooltip-content="Companias"
              onClick={(e) => {
                e.stopPropagation();
                navigate('/CompaniesList', { state: { numCont: item.numCont } });
              }}
            >
              <FontAwesomeIcon icon={faBuilding} />
            </button>
          )}

          <Tooltip id="edit-tooltip" className="custom-tooltip" />
          {hasPermission('CONTRACT_UPDATE_IDENTIFIER') && (role !== 'CHANEL_ADMIN' || item.saas === 0) && (
            <button
              className="icon-button company-button"
              onClick={() => handleUpdateIdentifiers(item)}
              data-tooltip-id="contract-tooltip"
              data-tooltip-content="Editar Identificadores"
            >
              <FontAwesomeIcon icon={faFilePen} />
            </button>
          )}
          <Tooltip id="edit-tooltip" className="custom-tooltip" />
          {hasPermission('ORDER_CREATE') && (role !== 'CHANEL_ADMIN' || item.saas === 0) && (
            <button
              className="icon-button company-button"
              onClick={() => handleAddUser(item)}
              data-tooltip-id="contract-tooltip"
              data-tooltip-content="Agregar usuarios"
            >
              <FontAwesomeIcon icon={faUserPlus} />
            </button>
          )}
            */}
        </div>
      </td>
    </>
  );

  return (
    <div className="home-container">
      <Header onLogout={handleLogout} title='Contratos' />
      <div className="home-content">
        <Section>
          <div className="filter-form">
            <div className="form-group-contract ">
              <input
                className="contract-input"
                type="text"
                id="search"
                value={tempSearch}
                onChange={(e) => {
                  setTempSearch(e.target.value);
                }}
                placeholder="Buscar..."
              />
            </div>
          </div>
          <div className="button-add">
            <button
              className="basic-custom-button"
              onClick={() => assignSearchValues()}
            >
              <FontAwesomeIcon className="basic-shortcut-icon" icon={faSearchPlus} />
              Buscar
            </button>
          </div>
          <div className="button-add-contract">
            {role === 'CHANEL_ADMIN' ? (
              <div className="button-add-contract">
                <button
                  className="basic-contract-button"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleVerifyDebt(data, customer)
                  }}
                >
                  <FontAwesomeIcon className="basic-shortcut-icon" icon={faFileCirclePlus} />
                  Crear Nuevo Contrato
                </button>
              </div>
            ) : (
              <div className="button-add-contract">
                {hasPermission('CONTRACT_CREATE') && (
                  <button
                    className="basic-contract-button"
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate('/ContractNew', { state: { contract: data, cif: customer } });
                    }}
                  >
                    <FontAwesomeIcon className="basic-shortcut-icon" icon={faFileCirclePlus} />
                    Crear Nuevo Contrato
                  </button>
                )}
              </div>
            )}
          </div>
        </Section>
        <Table
          title={`Lista de Contratos del Cliente (${name}) con cédula (${cif})`}
          rows={data}
          columns={columns}
          icon={faFileContract}
          renderRow={renderRow}
          currentPage={currentPage}
          totalItems={totalItems}
          itemsPerPage={itemsPerPage}
          onPageChange={(page) => setCurrentPage(page)}
          onRefresh={onRefreshThis}
          loading={loading}
          flagPagination={true}
        />
        {selectedItem && (
          <ContractStatus
            message={
              selectedItem.checkobservacion
                ? "Contrato Inactivo. ¿Desea Activarlo?"
                : "Contrato Activo. ¿Desea Inactivarlo?"
            }
            isVisible={modalVisible}
            onClose={handleCloseModal}
            numContId={selectedItem.numCont}
            block={selectedItem.checkobservacion}
          />
        )}
        {selectedItem && (
          <ContractIdentifiersModal
            isVisible={updateModal}
            onClose={handleCloseUpdateModal}
            contract={selectedItem}
          />
        )}
        {selectedItem && (
          <AddUserContract
            isVisible={addUserModal}
            onClose={handleCloseAddUser}
            contract={selectedItem}
          />
        )}

      </div>
      <SuccessNotification
        message={"Datos cargados correctamente"}
        isVisible={isSuccessVisible}
        onClose={() => setIsSuccessVisible(false)}
      />
      <ErrorNotification
        message="Ups! Ocurrio un Problema"
        isVisible={isErrorVisible}
        onClose={() => setIsErrorVisible(false)}
      />
      <LoginNotification
        message="Vuele a iniciar sesión"
        isVisible={isInfoVisible}
        onClose={() => isInfoVisible(false)}
        onLogout={handleLogout}
      />
      {/* Modal 
      {isModalVisible && (
        <div className="modal-overlay-edit">
          <div className="modal-content-edit">
            <ContractForm selectedRow={selectedRow} isEdit={isEdit} closeModal={handleModalClose} />
          </div>
        </div>
      )}*/}

      <SlideModal isOpen={isModalView} onClose={toggleModal} title="Detalles del COntrato">
        <div className="section-container-form">
          <h4 className="section-title-form">NRO. DE CONTRATO</h4>
          <hr className="section-divider-form" />
          <div className="form-group-form">
            <label>{viewItem?.alternative.numCont}</label>
          </div>

          <h4 className="section-title-form">CLIENTE</h4>
          <hr className="section-divider-form" />
          <div className="form-group-form">
            <label>{viewItem?.general.cliente}</label>
          </div>

          <h4 className="section-title-form">NRO. DE IDENTIFICADOR</h4>
          <hr className="section-divider-form" />
          <div className="form-group-form">
            <label>{viewItem?.alternative.numSerie}</label>
          </div>

          <h4 className="section-title-form">IDENTIFICADOR DE SERVIDOR</h4>
          <hr className="section-divider-form" />
          <div className="form-group-form">
            <label>{viewItem?.alternative.servidor}</label>
          </div>
          <h4 className="section-title-form">DISTRIBUIDOR</h4>
          <hr className="section-divider-form" />
          <div className="form-group-form">
            <label>{viewItem?.alternative.distribuidor}</label>
          </div>
          <h4 className="section-title-form">CADUCA</h4>
          <hr className="section-divider-form" />
          <div className="form-group-form">
            <label>
              {viewItem?.alternative.proxPago
                ? new Date(viewItem.alternative.proxPago).toLocaleDateString('es-ES', {
                  day: '2-digit',
                  month: 'long',
                  year: 'numeric'
                })
                : 'Fecha no disponible'}
            </label>
          </div>
          <h4 className="section-title-form">PRODUCTO</h4>
          <hr className="section-divider-form" />
          <div className="form-group-form">
            <label>{viewItem?.alternative.tipocontra}</label>
          </div>
          <h4 className="section-title-form">TIPO CONTRATO</h4>
          <hr className="section-divider-form" />
          <div className="form-group-form">
            <label>{viewItem?.alternative.tipoContrato}</label>
          </div>
          <h4 className="section-title-form">DESCRIPCIÓN</h4>
          <hr className="section-divider-form" />
          <div className="form-group-form">
            <label>{viewItem?.general.descripcion}</label>
          </div>
          <h4 className="section-title-form">OBSERVACIÓN</h4>
          <hr className="section-divider-form" />
          <div className="form-group-form">
            <label>{viewItem?.alternative.observacion}</label>
          </div>
          <h4 className="section-title-form">ESTADO</h4>
          <hr className="section-divider-form" />
          <div className="form-group-form">
            {viewItem?.alternative.checkobservacion === 1 ? 'Inactivo' : 'Activo'}
          </div>
          <h4 className="section-title-form">Nro de Servidores</h4>
          <hr className="section-divider-form" />
          <div className="form-group-form">
            <label>{viewItem?.alternative.numSer}</label>
          </div>
          <h4 className="section-title-form">Nro de Clientes</h4>
          <hr className="section-divider-form" />
          <div className="form-group-form">
            <label>{viewItem?.alternative.numCli}</label>
          </div>
        </div>
      </SlideModal>
      <DebtModal
        message={message}
        isVisible={debtModal}
        onClose={handleCloseDebModal}
        code={distributorToken}
      />
    </div>
  );
};

export default Contract;
