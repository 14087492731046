import React, { useState, useEffect, useCallback } from 'react';
import Header from '../../components/Header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SuccessNotification from '../../components/Notifications/SuccessNotification';
import ErrorNotification from '../../components/Notifications/ErrorNotification';
import LoginNotification from "../../components/Notifications/LoginNotification";
import { faEdit, faTrashAlt, faSearch, faBuilding, faUserGear, faDatabase } from '@fortawesome/free-solid-svg-icons';
import Table from '../../components/Table';
import AddUserCompany from '../../components/AddUserCompany';
import Section from '../../components/Section';
import apiClient from "../../axios";
import { Tooltip } from "react-tooltip";
import { useLocation, useNavigate } from 'react-router-dom';

const CompaniesManagement = ({ handleLogout }) => {
    const location = useLocation();
    const { numCont } = location.state || {};
    const [data, setData] = useState([]);
    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const itemsPerPage = 10;
    const [isSuccessVisible, setIsSuccessVisible] = useState(false);
    const [isErrorVisible, setIsErrorVisible] = useState(false);
    const [isInfoVisible, setIsInfoVisible] = useState(false);
    const [addUserModal, setAddUserModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const navigate = useNavigate();

    const hasPermission = (permission) => {
        const permissions = JSON.parse(localStorage.getItem('permissions')) || [];
        return permissions.includes(permission);
    };

    // Usa useCallback para memoizar fetchAllData
    const fetchAllData = async (page = 1) => {
        try {
            setLoading(true);
            const response = await apiClient.get(`/admin/companies?page=${page}`);
            if (Array.isArray(response.data.results)) {
                setData(response.data.results);
                setTotalItems(1);
            } else {
                setData([response.data]);
                setTotalItems(1);
            }
        } catch (error) {
            setIsErrorVisible(true);
            if (error.response && error.response.status === 401) {
                setIsInfoVisible(true);
            }
        } finally {
            setIsSuccessVisible(true);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchAllData(currentPage);
    }, [currentPage]);

    const handleRefresh = () => {
        fetchAllData(currentPage);
    };


    const handleAddUser = (item) => {
        setSelectedItem(item);
        setAddUserModal(true);
    }
    const handleCloseAddUser = () => {
        setSelectedItem(null);
        handleRefresh()
    };

    const handleSearch = useCallback(async (page = 1) => {
        try {
            const params = new URLSearchParams();
            if (search) params.append('search', search);

            const response = await apiClient.get(`/admin/companies?${params.toString()}&page=${page}`);

            if (Array.isArray(response.data.results)) {
                setData(response.data.results);
            } else {
                setData([response.data.results]);
            }
            setTotalItems(response.data.total);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                setIsInfoVisible(true);
            }
        }
    }, [search]);
    const handleFresh = async (code) => {
        handleSearch(1)
    };

    const handleDelete = async (id) => {
        try {
            const url = `/admin/companies/${id}`;
            await apiClient.delete(url);
            handleRefresh();
        } catch (error) {
            setIsErrorVisible(true);
            if (error.response && error.response.status === 401) {
                setIsInfoVisible(true);
            }
        }
    };

    const columns = [
        { title: "Id", key: "id" },
        { title: "Contrato", key: "contract" },
        { title: "Nombre", key: "name" },
        { title: "Descripción", key: "descripction" },
        { title: "Secuencia", key: "sequence" },
        { title: "RUC", key: "ruc" },
        { title: "Acciones", key: "acciones" },
    ];

    const renderRow = (item, index) => (
        <>
            <td>{item.id}</td>
            <td>{item.contract}</td>
            <td>{item.name}</td>
            <td>{item.description}</td>
            <td>{item.sequence || '-'}</td>
            <td>{item.ruc}</td>
            <td>
                <div className="button-container">
                    <Tooltip id="edit-tooltip" className="custom-tooltip" />
                    {hasPermission('COMPANY_ADMIN_UPDATE') && (
                        <button
                            data-tooltip-id="edit-tooltip"
                            className="icon-button edit-button"
                            data-tooltip-content="Editar"
                            onClick={(e) => {
                                e.stopPropagation();
                                navigate(`/CompaniesForm/${item.id}`, { state: { numCont: numCont } });
                            }}
                        >
                            <FontAwesomeIcon icon={faEdit} />
                        </button>
                    )}
                    <Tooltip id="delete-tooltip" className="custom-tooltip" />
                    {hasPermission('COMPANY_ADMIN_DELETE') && (
                        <button
                            data-tooltip-id="delete-tooltip"
                            data-tooltip-content="Eliminar"
                            className="icon-button delete-button"
                            onClick={() => handleDelete(item.id)}
                        >
                            <FontAwesomeIcon icon={faTrashAlt} />
                        </button>
                    )}
                    <Tooltip id="edit-tooltip" className="custom-tooltip" />
                    {hasPermission('COMPANY_ASSIGN') && (
                        <button
                            data-tooltip-id="edit-tooltip"
                            className="icon-button edit-button"
                            data-tooltip-content="Usuarios"
                            onClick={() => handleAddUser(item)}
                        >
                            <FontAwesomeIcon icon={faUserGear} />
                        </button>
                    )}
                    <Tooltip id="edit-tooltip" className="custom-tooltip" />
                    {hasPermission('CREDENTIAL_KPI_CREATE') && (
                        <button
                            data-tooltip-id="edit-tooltip"
                            className="icon-button edit-button"
                            data-tooltip-content="Conexiones KPI"
                            onClick={(e) => {
                                e.stopPropagation();
                                navigate('/ConnectionKpiList', {
                                    state: {
                                        numCont: item.contract,
                                        companyId: item.id
                                    }
                                });
                            }}
                        >
                            <FontAwesomeIcon
                                icon={faDatabase} />
                        </button>
                    )}
                </div>
            </td>

        </>
    );

    return (
        <div className="home-container">
            <Header onLogout={handleLogout} title='Compañías' />
            <div className="home-content">
                <Section>
                    <div className="filter-form">
                        <div className="basic-info-form-group">
                            <input
                                type="text"
                                id="customer"
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                                placeholder="Compañía"
                            />
                        </div>
                        <button className="search-button-customer" onClick={handleFresh}>
                            <FontAwesomeIcon icon={faSearch} className="search-icon" />
                            Buscar
                        </button>
                    </div>
                    <div className="button-add">
                        {hasPermission('COMPANY_ADMIN_CREATE') && (
                            <button
                                className="basic-custom-button"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    navigate('/CompaniesForm', { state: { numCont: numCont } });
                                }}
                            >
                                <FontAwesomeIcon className="basic-shortcut-icon" icon={faBuilding} />
                                Crear Nueva compañía
                            </button>
                        )}
                    </div>
                </Section>
                <Table
                    title="Lista de compañías"
                    rows={data}
                    columns={columns}
                    icon={faBuilding}
                    renderRow={renderRow}
                    currentPage={currentPage}
                    totalItems={totalItems}
                    itemsPerPage={itemsPerPage}
                    onPageChange={setCurrentPage}
                    onRefresh={handleRefresh}
                    loading={loading}
                    flagPagination={true}
                />
                <SuccessNotification
                    message={"Se ha cargado correctamente"}
                    isVisible={isSuccessVisible}
                    onClose={() => setIsSuccessVisible(false)}
                />
                <ErrorNotification
                    message="Ups! Ocurrio un Problema"
                    isVisible={isErrorVisible}
                    onClose={() => setIsErrorVisible(false)}
                />
                <LoginNotification
                    message="Vuele a iniciar sesión"
                    isVisible={isInfoVisible}
                    onClose={() => setIsInfoVisible(false)}
                    onLogout={handleLogout}
                />
                {selectedItem && (
                    <AddUserCompany
                        isVisible={addUserModal}
                        onClose={handleCloseAddUser}
                        element={selectedItem}
                    />
                )}
            </div>
        </div>
    );
};

export default CompaniesManagement;
