import React, { useState, useEffect, useCallback } from 'react';
import Header from '../../components/Header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SuccessNotification from '../../components/Notifications/SuccessNotification';
import ErrorNotification from '../../components/Notifications/ErrorNotification';
import LoginNotification from "../../components/Notifications/LoginNotification";
import SuccessProcess from '../../components/Notifications/SuccessProcess';
import { faEdit, faTrashAlt, faPlus, faEye, faSearch, faServer, faCircleCheck, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import Table from '../../components/Table';
import Section from '../../components/Section';
import SlideModal from '../../components/SlideModal';
import apiClient from "../../axios";
import { Tooltip } from "react-tooltip";
import { useNavigate } from 'react-router-dom';

const ServerList = ({ handleLogout }) => {
    const [data, setData] = useState([]);
    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [selectedItem, setSelectedItem] = useState(null);
    const itemsPerPage = 10;
    const [isSuccessVisible, setIsSuccessVisible] = useState(false);
    const [isErrorVisible, setIsErrorVisible] = useState(false);
    const [isInfoVisible, setIsInfoVisible] = useState(false);
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [successVisible, setSuccessVisible] = useState(false);
    const [message2, setMessage2] = useState('');

    const toggleModal = async (item) => {
        if (!isModalOpen) {
            try {
                const response = await apiClient.get(`/servers/${item.serverId}`);
                const staff = {
                    general: item,
                    alternative: response.data,
                };
                setSelectedItem(staff);
                setIsModalOpen(true);
            } catch (error) {
                setIsErrorVisible(true);
                if (error.response && error.response.status === 401) {
                    setIsInfoVisible(true);
                }
            } finally {
                setIsSuccessVisible(true);
            }
        } else {
            setIsModalOpen(false);
        }
    };

    const fetchAllData = async (page = 1) => {
        try {
            setLoading(true);
            const response = await apiClient.get(`/servers?page=${page}`);
            if (Array.isArray(response.data.results)) {
                setData(response.data.results);
                setTotalItems(response.data.total);
            } else {
                setData([response.data.results]);
                setTotalItems(1);
            }
        } catch (error) {
            setIsErrorVisible(true);
            if (error.response && error.response.status === 401) {
                setIsInfoVisible(true);
            }
        } finally {
            setIsSuccessVisible(true);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchAllData(currentPage);
    }, [currentPage]);

    const handleRefresh = () => {
        fetchAllData(currentPage);
    };

    const handleSearch = useCallback(async (page = 1) => {
        try {
            // Construir la URL con los query params
            const params = new URLSearchParams();
            if (search) params.append('search', search);

            const response = await apiClient.get(`/servers?${params.toString()}&page=${page}`);

            if (Array.isArray(response.data.results)) {
                setData(response.data.results); // Si es un array, lo dejamos tal cual
            } else {
                setData([response.data.results]); // Si es un objeto, lo encapsulamos en un array
            }
            setTotalItems(response.data.total);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                setIsInfoVisible(true);
            }
        }
    }, [search]);
    const handleFresh = async (code) => {
        handleSearch(1)
    };

    const handleDelete = async (code) => {
        try {
            const url = `/servers/${code}`;
            await apiClient.delete(url);
            handleRefresh();
        } catch (error) {
            setIsErrorVisible(true);
            if (error.response && error.response.status === 401) {
                setIsInfoVisible(true);
            }
        }
    };

    const handleStatusClick = async (item) => {
        try {
            const flag_active = !item.flagActive;
            const payloadUpdate = {
                flag_active
            };
            const response = await apiClient.patch(
                `/servers/${item.serverId}`,
                payloadUpdate
            );
            if (response.status === 200) {
                setMessage2(
                    flag_active 
                        ? 'Servidor Activado exitosamente.' 
                        : 'Servidor Desactivado exitosamente.'
                );
                setSuccessVisible(true);
            }
        }
        catch (error) {
            if (error.response && error.response.status === 401) {
                setIsInfoVisible(true);
            } else if (error.response?.data?.message.startsWith("ONLY_ONE_ACTIVE_SERVER_ALLOWED")) {
                setSuccessVisible(true);
                setMessage2("Ya existe un Servidor activo, desactivelo primero y luego vuelva a intentar.");
            }
        }
    };

    const handleCloseSuccess = () => {
        setSuccessVisible(false);
        handleRefresh();
    };

    const columns = [
        { title: "Id", key: "id" },
        { title: "Descripción", key: "descripción" },
        { title: "Contrato", key: "contrato" },
        { title: "Nro. Usuario", key: "nuser" },
        { title: "Nro. Usuario Actualmente", key: "nuserac" },
        { title: "Estado", key: "estado" },
        { title: "Acciones", key: "acciones" },
    ];

    const renderRow = (item, index) => (
        <>
            <td>{item.serverId}</td>
            <td>{item.description}</td>
            <td>{item.contract}</td>
            <td>{item.maxUser}</td>
            <td>{item.currentUsers}</td>
            <td>
                {item.flagActive ? (
                    <button
                        className="status_button"
                        onClick={() => handleStatusClick(item)}
                    >
                        <FontAwesomeIcon
                            icon={faCircleCheck}
                            style={{ color: "green", fontSize: "24px" }}
                        />
                    </button>
                ) : (
                    <button
                        className="status_button"
                        onClick={() => handleStatusClick(item)}
                    >
                        <FontAwesomeIcon
                            className="status_icon"
                            icon={faCircleXmark}
                            style={{ color: "red", fontSize: "24px" }}
                        />
                    </button>
                )}
            </td>
            <td>
                <div className="button-container">
                    <Tooltip id="edit-tooltip" className="custom-tooltip" />
                    <button
                        data-tooltip-id="edit-tooltip"
                        className="icon-button edit-button"
                        data-tooltip-content="Editar"
                        onClick={(e) => {
                            e.stopPropagation();
                            navigate(`/ServerForm/${item.serverId}`);
                        }}
                    >
                        <FontAwesomeIcon icon={faEdit} />
                    </button>
                    <Tooltip id="delete-tooltip" className="custom-tooltip" />
                    <button
                        data-tooltip-id="delete-tooltip"
                        data-tooltip-content="Eliminar"
                        className="icon-button delete-button"
                        onClick={() => handleDelete(item.serverId)}
                    >
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </button>
                    <button
                        className="icon-button company-button"
                        onClick={() => toggleModal(item)}
                        data-tooltip-id="contract-tooltip"
                        data-tooltip-content="Detalle"
                    >
                        <FontAwesomeIcon icon={faEye} />
                    </button>

                </div>
            </td>

        </>
    );


    return (
        <div className="home-container">
            <Header onLogout={handleLogout} title='Servidores' />
            <div className="home-content">
                <Section>
                    <div className="filter-form">
                        <div className="basic-info-form-group">
                            <input
                                type="text"
                                id="customer"
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                                placeholder="Servidor"
                            />
                        </div>
                        <button className="search-button-customer" onClick={handleFresh}>
                            <FontAwesomeIcon icon={faSearch} className="search-icon" />
                            Buscar
                        </button>

                    </div>
                    <div className="button-add">
                        <button
                            className="basic-custom-button"
                            onClick={(e) => {
                                e.stopPropagation();
                                navigate('/ServerForm');
                            }}
                        >
                            <FontAwesomeIcon className="basic-shortcut-icon" icon={faPlus} />
                            Crear Nuevo Servidor
                        </button>
                    </div>
                </Section>
                <Table
                    title="Lista de Servidores"
                    rows={data}
                    columns={columns}
                    icon={faServer}
                    renderRow={renderRow}
                    currentPage={currentPage}
                    totalItems={totalItems}
                    itemsPerPage={itemsPerPage}
                    onPageChange={setCurrentPage}
                    onRefresh={handleRefresh}
                    loading={loading}
                    flagPagination={true}
                />
                <SuccessNotification
                    message={"Se ha cargado correctamente"}
                    isVisible={isSuccessVisible}
                    onClose={() => setIsSuccessVisible(false)}
                />
                <ErrorNotification
                    message="Ups! Ocurrio un Problema"
                    isVisible={isErrorVisible}
                    onClose={() => setIsErrorVisible(false)}
                />
                <LoginNotification
                    message="Vuele a iniciar sesión"
                    isVisible={isInfoVisible}
                    onClose={() => setIsInfoVisible(false)}
                    onLogout={handleLogout}
                />

                <SuccessProcess
                    enca="Información"
                    message={message2}
                    isVisible={successVisible}
                    onClose={handleCloseSuccess}
                    redirectOnOk={false}
                />

                <SlideModal isOpen={isModalOpen} onClose={toggleModal} title="Detalles del Servidor">
                    <div className="section-container-form">
                        <h4 className="section-title-form">ID</h4>
                        <hr className="section-divider-form" />
                        <div className="form-group-form">
                            <label>{selectedItem?.general.serverId}</label>
                        </div>

                        <h4 className="section-title-form">DESCRIPCIÓN</h4>
                        <hr className="section-divider-form" />
                        <div className="form-group-form">
                            <label>{selectedItem?.general.description}</label>
                        </div>

                        <h4 className="section-title-form">NRO. CONTRATO</h4>
                        <hr className="section-divider-form" />
                        <div className="form-group-form">
                            <label>{selectedItem?.general.contract}</label>
                        </div>

                        <h4 className="section-title-form">NRO. USUARIOS</h4>
                        <hr className="section-divider-form" />
                        <div className="form-group-form">
                            <label>{selectedItem?.general.maxUser}</label>
                        </div>

                        <h4 className="section-title-form">NRO. USUARIOS ACTUALMENTE</h4>
                        <hr className="section-divider-form" />
                        <div className="form-group-form">
                            <label>{selectedItem?.general.currentUsers}</label>
                        </div>

                        <h4 className="section-title-form">ESTADO</h4>
                        <hr className="section-divider-form" />
                        <div className="form-group-form">
                            <label>{selectedItem?.general.flagActive === 1 ? "Activo" : "Inactivo"}</label>
                        </div>

                        <h4 className="section-title-form">IP DE ACCESO</h4>
                        <hr className="section-divider-form" />
                        <div className="form-group-form">
                            <label>{selectedItem?.general.ipAccess}</label>
                        </div>

                        <h4 className="section-title-form">URL DE ACCESO</h4>
                        <hr className="section-divider-form" />
                        <div className="form-group-form">
                            <label>{selectedItem?.general.urlAccess}</label>
                        </div>

                        <h4 className="section-title-form">PUERTO DE ACCESO</h4>
                        <hr className="section-divider-form" />
                        <div className="form-group-form">
                            <label>{selectedItem?.general.portAccess}</label>
                        </div>

                        <h4 className="section-title-form">USUARIO DE ADMINISTRADOR</h4>
                        <hr className="section-divider-form" />
                        <div className="form-group-form">
                            <label>{selectedItem?.general.userManager}</label>
                        </div>

                        <h4 className="section-title-form">CLAVE DE ADMINISTRADOR</h4>
                        <hr className="section-divider-form" />
                        <div className="form-group-form">
                            <label>{selectedItem?.general.passManager}</label>
                        </div>
                    </div>
                </SlideModal>
            </div>
        </div>
    );
};

export default ServerList;
